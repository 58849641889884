import { CNavItem } from '@coreui/react'

const merchantNav = [
  {
    component: CNavItem,
    name: 'Campaigns',
    to: '/widgets',
  },
  {
    component: CNavItem,
    name: 'trees',
    to: '/trees',
  },
  {
    component: CNavItem,
    name: 'Reports',
    to: '/transaction-report',
  },
  {
    component: CNavItem,
    name: 'Invoices',
    to: '/partnerInvoice',
  },
  {
    component: CNavItem,
    name: 'API',
    to: '/merchantApiManagement',
  },
]

export default merchantNav
