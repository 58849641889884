import { CNavItem } from '@coreui/react'

const nav = [
  // {
  //   component: CNavItem,
  //   name: 'Home',
  //   to: '/dashboard',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Brand Settings',
  //   to: '/brandSettings',
  // },
  {
    component: CNavItem,
    name: 'Campaigns',
    to: '/evcashCampaigns',
  },
  {
    component: CNavItem,
    name: 'Offers',
    to: '/evcashOffers',
  },
  {
    component: CNavItem,
    name: 'Reports',
    to: '/performanceReport',
  },
  // {
  //   component: CNavItem,
  //   name: 'API',
  //   to: '/apiManagement',
  // },
]

export default nav
