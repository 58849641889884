import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getToken } from 'src/helpers/GeneralFunctions'
import { jwtDecode } from 'jwt-decode'

const BrandContext = createContext()

const BrandProvider = ({ children }) => {
  const [selectedBrand, setSelectedBrand] = useState('')
  const token = getToken()
  useEffect(() => {
    let decoded = ''
    if (token) {
      decoded = jwtDecode(token)
      setSelectedBrand(decoded.brand_id)
    }
  }, [])

  const updateSelectedBrand = (brand) => {
    setSelectedBrand(brand)
  }

  return (
    <BrandContext.Provider value={{ selectedBrand, updateSelectedBrand }}>
      {children}
    </BrandContext.Provider>
  )
}

BrandProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export { BrandContext, BrandProvider }
