import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CImage, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

//import { caremilesLogoNegative } from 'src/assets/brand/caremiles-logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../nav'

import useFetch from 'src/hooks/useFetch'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const APIS_URL = process.env.REACT_APP_APIS_URL
  const { data } = useFetch(`${APIS_URL}/v2/market/brand`)
  const [brandLogo, setBrandLogo] = useState(null)
  useEffect(() => {
    async function getBrand() {
      if (data) {
        const { code } = data
        if (code === 200) {
          const brand = data.data
          console.log(brand.logo)
          if (brand.logo) setBrandLogo(brand.logo)
        }
      }
    }
    getBrand()
  }, [data])
  return (
    <CSidebar
      // position="fixed"
      // unfoldable={unfoldable}
      // visible={sidebarShow}
      onVisibleChange={(visible) => {
        // dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      {/* <CSidebarBrand className="d-none d-md-flex" to="/">
        {<CIcon className="sidebar-brand-full" icon={brandLogo} height={35} />}
        <CIcon className="sidebar-brand-narrow" icon={sygnet} />
        <CImage className="sidebar-brand-full" src={brandLogo} width={240} />
      </CSidebarBrand> */}
      {/* <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
