import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeader, CHeaderBrand, CHeaderNav, CHeaderToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

import { AppHeaderDropdown } from './header/index'
import { AppHeaderMerchant } from './header/index'
import { AppHeaderMastercard } from './header/index'
import { AppHeaderLogout } from './header/index'
import { jwtDecode } from 'jwt-decode'

import caremilesLogo from 'src/assets/images/caremiles-logo-white.png'
import { getToken } from '../helpers/GeneralFunctions'
const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const token = getToken()

  let decoded = ''
  let accountType = ''

  if (token) {
    decoded = jwtDecode(token)
    accountType = decoded?.accountType || decoded?.account_type
  }

  return (
    <CHeader position="sticky" className="mb-4 pb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={caremilesLogo} height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="ms-3">
          {token && accountType === 'ppc_partner' ? (
            <AppHeaderMerchant />
          ) : token && accountType === 'ppc_admin' ? (
            <AppHeaderMastercard />
          ) : token ? (
            <AppHeaderDropdown />
          ) : (
            <AppHeaderLogout />
          )}
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
