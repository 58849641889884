import { CNavItem } from '@coreui/react'

const mastercardNav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/ppcAdminDashboard',
  },
  {
    component: CNavItem,
    name: 'Partner Management',
    to: '/merchantManagement',
  },
  {
    component: CNavItem,
    name: 'Invoice Management',
    to: '/mastercardInvoiceManagement',
  },
  {
    component: CNavItem,
    name: 'Reports',
    to: '/ppcAdminReport',
  },
]

export default mastercardNav
