import React, { useContext, useEffect, useState } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImage,
} from '@coreui/react'
// import caremilesLogo from 'src/assets/images/caremiles-logo-white.png'
import { cilLockLocked, cilAccountLogout } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { logout } from '../../helpers/GeneralFunctions'
import { AppSidebarNav } from '../AppSidebarNav'
import navigation from '../../header_nav/merchantNav'
import useFetch from 'src/hooks/useFetch'
import { getRole } from '../../helpers/GeneralFunctions'
import caremilesLogo from 'src/assets/images/hub_logo_new.svg'
import { BrandContext } from './BrandContext'

const APIS_URL = process.env.REACT_APP_APIS_URL

const AppHeaderMerchant = () => {
  const { data } = useFetch(`${APIS_URL}/v2/market/get-profile`)
  const [accountData, setAccountData] = useState(
    `https://ui-avatars.com/api/?name=Admin&size=40&rounded=true`,
  )
  const [brandInfoData, setBrandInfoData] = useState([{ id: 1, name: 'caremiles' }])
  const { selectedBrand, updateSelectedBrand } = useContext(BrandContext)
  useEffect(() => {
    async function getProfileData() {
      if (data) {
        const { code } = data
        if (code === 200) {
          const profileData = data?.data
          setAccountData(
            `https://ui-avatars.com/api/?name=${
              profileData.name === '' || profileData.name === null ? 'AD' : profileData.name
            }&size=40&rounded=true`,
          )
          // console.log(profileData.brand, 'sssssssss')
          setBrandInfoData(profileData.brand)
        }
      }
    }
    getProfileData()
  }, [data])

  const { accounts } = { accounts: [{ name: 'Admin' }] }
  const handleLogout = () => {
    logout()
  }

  const role = getRole()

  return (
    <div className="container-fluid">
      <div className="logo-div">
        {/* <h4>Hub@Caremiles</h4> */}
        <a href="/widgets">
          <CImage src={caremilesLogo} alt="Logo" style={{ width: '10rem' }} className="pb-1" /> |
          GREEN CHECKOUT PARTNER
        </a>
      </div>
      <div className="top-navigation pl-3 mt-3">
        <AppSidebarNav items={navigation} />
        {brandInfoData.length > 0 && (
          <li className="brands-dd">
            <select value={selectedBrand} onChange={(e) => updateSelectedBrand(e.target.value)}>
              {brandInfoData.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
          </li>
        )}
        <CDropdown variant="nav-item">
          <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
            <CAvatar src={accountData} size="md" />
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            {/* <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader> */}
            {role === 'admin' ? (
              <CDropdownItem href="/profile">
                <CIcon icon={cilLockLocked} className="me-2" />
                Account Management
              </CDropdownItem>
            ) : (
              ''
            )}

            <CDropdownItem
              href="#"
              onClick={(e) => {
                e.preventDefault()
                handleLogout('redirect')
              }}
            >
              <CIcon icon={cilAccountLogout} className="me-2" />
              Logout
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </div>
    </div>
  )
}

export default AppHeaderMerchant
