import React, { lazy, useContext, useEffect, useState } from 'react'
import { AppFooter, AppHeader } from '../../components/index'
import { useNavigate } from 'react-router-dom'
import { CButton, CCol, CRow, CContainer, CSpinner, CImage } from '@coreui/react'
import './contactUs.css'
import phoneIcon from 'src/assets/images/phone.png'
import emailIcon from 'src/assets/images/mail.png'
import locationIcon from 'src/assets/images/location.png'
const APIS_URL = process.env.REACT_APP_APIS_URL

const CONTACT = () => {
  const navigate = useNavigate()

  return (
    <>
      {/* <CSpinner color="primary" /> */}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light bg-white">
        <AppHeader />
        <div className="body-bg flex-grow-1 h-100">
          <div className="radius-green"></div>
          <div className="flex-grow-1 h-100 pt-3 pb-3"></div>
          <CContainer fluid className="custom-color">
            <CRow className="mb-2 pt-5 ml-2 mr-2">
              <CCol>
                <h1>
                  <strong>Contact Us</strong>
                </h1>
              </CCol>
            </CRow>
          </CContainer>
          <CContainer fluid>
            <CRow className="mb-5 ml-2">
              <p className="contact-content">
                Contact us through live chat 9 AM to 6 PM (Pacific time) or email us, we will get
                touch within 72 hours.
              </p>
              <CCol md={12}>
                <CImage src={phoneIcon} className="contact-images" alt="Phone" />
                <a href="tel:+18774682004" className="ml-3 contact-number">
                  +18774682004
                </a>
              </CCol>
              <CCol md={12}>
                <CImage src={emailIcon} className="contact-images" alt="Email" />
                <a href="hello@caremiles.app" className="ml-3 contact-number">
                  hello@caremiles.app
                </a>
              </CCol>
              <CCol md={12}>
                <CImage src={locationIcon} className="contact-images" alt="Location" />
                <a
                  href="http://maps.google.com/?q=548%20Market%20St,%20PMB%2046604,%20San%20Francisco,%20California%2094104"
                  className="ml-3 contact-number"
                >
                  548 Market St, PMB 46604, San Francisco, California 94104
                </a>
              </CCol>
            </CRow>
          </CContainer>
        </div>
        <div className="position-absolute bottom-0 w-100">
          <AppFooter />
        </div>
      </div>
    </>
  )
}

export default CONTACT
