const Swal = require('sweetalert2')

function getToken() {
  const token = localStorage.getItem('access-token')
  return token
}

function getCroppedLogo() {
  const logo = localStorage.getItem('croppedLogo')
  return logo
}

function saveCroppedLogo(logo) {
  localStorage.setItem('croppedLogo', logo)
}
function getCroppedLogoName() {
  const logo = localStorage.getItem('croppedLogo')
  return logo
}

function saveCroppedLogoName(logo) {
  localStorage.setItem('croppedLogo', logo)
}

function generateErrorWithStatusText(res) {
  let err = Error('could not fetch the data for that resource')
  err.statusText = res.statusText
  return err
}

function logout() {
  localStorage.clear()
  window.location.href = `/`
}

function saveToken(token) {
  localStorage.setItem('access-token', token)
}

function saveRole(role) {
  localStorage.setItem('role', role)
}

function getRole() {
  const role = localStorage.getItem('role')
  return role
}

function saveAccountType(accountType) {
  localStorage.setItem('account-type', accountType)
}

function getAccountType() {
  const accountType = localStorage.getItem('account-type')
  return accountType
}

function saveWelcomePopupState(state) {
  localStorage.setItem('is-welcome-popup-shown', state)
}

function getWelcomePopupState() {
  const state = localStorage.getItem('is-welcome-popup-shown')
  return state
}

function saveBrandCard(role) {
  localStorage.setItem('brand-card', role)
}

function getBrandCard() {
  const card = localStorage.getItem('brand-card')
  return card
}

function isAuthenticated() {
  const token = getToken()
  if (token && token !== 'undefined') {
    return true
  }
  return false
}
function showConfirmationAlert(confirmCallBack, confirmBtnText, funcData) {
  Swal.fire({
    title: 'Are you sure?',
    //text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#66BF00',
    cancelButtonColor: '#FB7676',
    confirmButtonText: confirmBtnText,
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallBack(funcData)
    }
  })
}

const customSort = (rows, selector, direction) => {
  return rows.sort((rowA, rowB) => {
    // use the selector function to resolve your field names by passing the sort comparitors
    let aField = selector(rowA)
    let bField = selector(rowB)
    let comparison = 0

    if (aField) {
      comparison = 1
    }
    if (bField) {
      comparison = -1
    }
    if (aField && bField) {
      comparison = 0
      if (aField.props || bField.props) {
        if (aField.props.value > bField.props.value) {
          comparison = 1
        } else if (aField.props.value < bField.props.value) {
          comparison = -1
        }
      } else {
        if (typeof aField == 'string') {
          aField = aField.toLocaleLowerCase()
          if (!isNaN(Date.parse(aField))) {
            aField = Date.parse(aField)
          }
        }
        if (typeof bField == 'string') {
          bField = bField.toLocaleLowerCase()
          if (!isNaN(Date.parse(bField))) {
            bField = Date.parse(bField)
          }
        }
        if (aField > bField) {
          comparison = 1
        } else if (aField < bField) {
          comparison = -1
        }
      }
    }
    return direction === 'desc' ? comparison * -1 : comparison
  })
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const validateUrl = (input) => {
  const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/
  return urlRegex.test(input)
}

const getRequestOptions = (method) => {
  const headers = { 'access-token': getToken() }
  const requestOptions = {
    method,
    headers: { ...headers },
  }
  return requestOptions
}

const convertToTitleCase = (str) => {
  return str
    ? str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : str
}

const enableButtonAfterInterval = (fun, sec = 1) => setTimeout(() => fun(false), sec * 1000)

module.exports = {
  getToken,
  generateErrorWithStatusText,
  logout,
  saveToken,
  isAuthenticated,
  showConfirmationAlert,
  customSort,
  saveCroppedLogoName,
  saveCroppedLogo,
  getCroppedLogoName,
  getCroppedLogo,
  formatNumber,
  validateUrl,
  getRequestOptions,
  convertToTitleCase,
  enableButtonAfterInterval,
  saveRole,
  getRole,
  saveBrandCard,
  getBrandCard,
  saveWelcomePopupState,
  getWelcomePopupState,
  saveAccountType,
  getAccountType,
}
