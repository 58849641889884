import React, {
  createContext,
  ReactNode,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import group1 from '../offerImages/group1.png'
import group2 from '../offerImages/group2.png'
import group3 from '../offerImages/group3.png'
import PropTypes from 'prop-types'
// const fetchTodoList = async (id: string) => {
//   const toDoListItems = await getData(`ToDoList/${encodeURIComponent(id)}`)
//   return toDoListItems
// }
// export const WidgetsContext = (createContext < WidgetsContext) | (null > null)
export const WidgetsContext = React.createContext()
export function WidgetsContextProvider({ children }) {
  const searchParams = new URLSearchParams(window.location.search)
  let edit = JSON.parse(searchParams.get('edit'))
  // console.log(edit, 'ffffffffff==')

  useEffect(() => {
    // console.log(edit?.show_toogle, 'sssfffffffff', showToggle)
    edit?.title && setbrandingTitle(edit.title)
    edit?.message && setbrandingMessage(edit.message)
    edit?.show_toogle && setshowToggle(edit.show_toogle)
    edit?.powered_by && setGamificationOptions(edit.powered_by)
    edit?.package_type && setCommercialsOptions(edit.package_type)
    edit?.contributed_trees && setevcahsMinContribute(edit.contributed_trees)
    edit?.partial_tree_price && setevcahsMinContributePrice(edit.partial_tree_price)
    edit?.gamification_type && setGamificationOptions1(edit.gamification_type)
    edit?.custom_offer_message && setTrees(edit.custom_offer_message)
    edit?.enable_locale && setCurrencyOptions(edit.enable_locale)
    edit?.currency_type && setCurrencyOptions(edit.currency_type)
    edit?.prefered_currencies && setWidgets(edit.prefered_currencies)
    edit?.language_type && setLanguageOptions(edit.language_type)
    edit?.prefered_languages && setLanguageWidgets(edit.prefered_languages)
    edit?.thankyou_title && setthankyouTitle(edit.thankyou_title)
    edit?.thankyou_message && setthankyouMessage(edit.thankyou_message)
    edit?.thankyou_powered_by && setthankyouPowerdBy(edit.thankyou_powered_by)
    edit?.show_by && setTreePlanted(edit.show_by)
    edit?.project && setprojectId(edit.project)
    // edit?.no_of_plants && console.log(edit.no_of_plants)
    console.log('commercialsOptions', edit?.project)
  }, [edit?.id])
  const [step, setStep] = useState(1)
  // const [edits, setEdits] = useState(edit)
  const [brandingTitle, setbrandingTitle] = useState('Contribute 10c to plant trees')
  const [brandingMessage, setbrandingMessage] = useState(
    'We will email you a digital certificate on your checkout.',
  )
  const [brandingFile, setbrandingFile] = useState('')
  const [gamificationOptions, setGamificationOptions] = useState(
    'https://sacaremilescentralus.blob.core.windows.net/vehicles/master-card-icon.png',
  )
  const [gamificationOptions1, setGamificationOptions1] = useState('Custom_Offer')
  const [gamificationOptionsText, setGamificationOptionsText] = useState('Mastercard')
  const [thankyouPowerdByText, setThankyouPowerdByText] = useState('Caremiles')
  const [commercialsOptions, setCommercialsOptions] = useState('Full_Tree')
  const [evcashProjects, setevcashProjects] = useState(0)
  const [evcahsMinContribute, setevcahsMinContribute] = useState('2')
  const [evcahsMinContributePrice, setevcahsMinContributePrice] = useState(
    process.env.REACT_APP_PARTIALTREE,
  )
  const [evcashPreview, setevcashPreview] = useState(true)
  // const [switch, setSwitch] = useState(true)
  const [plantNo, setPlantNo] = useState('1')
  const [trees, setTrees] = useState('')
  const [localSwitch, setLocalSwitch] = useState(true)
  const [currencyOptions, setCurrencyOptions] = useState('US_Dollars')
  const [languageOptions, setLanguageOptions] = useState('English_(USA)')
  const [widgets, setWidgets] = useState([])
  const [evcashCurrency, setEvcashCurrency] = useState('')
  const [evcashCurrencyObj, setEvcashCurrencyObj] = useState([])
  const [currencyType, setCurrencyType] = useState('')
  const [locLangevcashCurrency, setLocLangevcashCurrency] = useState('')
  const [locLangplantTitle, setLocLangplantTitle] = useState('')
  const [locLangbrandingMessage, setLocLangbrandingMessage] = useState('')
  const [locLangplantNo, setLocLangplantNo] = useState('')
  const [locLangtrees, setLocLangtrees] = useState('')
  const [BrandInformation, setBrandInformation] = useState()
  const [languageWidgets, setLanguageWidgets] = useState([])
  const [projects, setprojects] = useState([])
  const [locLangevcashCurrencyObj, setLocLangevcashCurrencyObj] = useState([])
  const [evcashThankyouPreview, setevcashThankyouPreview] = useState(true)
  const [showToggle, setshowToggle] = useState(true)
  const [widgetId, setWidgetId] = useState()
  const [projectId, setprojectId] = useState('')
  const [customerOffer, setcustomerOffer] = useState('')
  const [thankyouTitle, setthankyouTitle] = useState('Thank You for planting trees')
  const [localthankyouTitle, setlocalthankyouTitle] = useState('')
  const [localthankyouMessage, setlocalthankyouMessage] = useState('')
  const [userInformation, setuserInformation] = useState([])
  const [statickeyorder, setstatickeyorder] = useState(
    'Plant trees and your order can be free (terms apply).',
  )
  const [statickeydelivery, setstatickeydelivery] = useState(
    'Plant trees and your delivery can be free (terms apply).',
  )
  const [WithOurCompliments, setWithOurCompliments] = useState('')
  const [thankyouMessage, setthankyouMessage] = useState(
    'Your contribution has been accepted and email containing your certificate is on its way.',
  )
  const [thankyouPowerdBy, setthankyouPowerdBy] = useState(
    'https://sacaremilescentralus.blob.core.windows.net/vehicles/caremiles_icon.png',
  )
  let evcashPoweredByOptions = [
    {
      value: 'https://sacaremilescentralus.blob.core.windows.net/vehicles/master-card-icon.png',
      label: 'Mastercard',
      imageSrc: 'https://sacaremilescentralus.blob.core.windows.net/vehicles/master-card-icon.png',
    },
    {
      value: 'https://sacaremilescentralus.blob.core.windows.net/vehicles/trees_org.png',
      label: 'Priceless Planet Coalition',
      imageSrc: 'https://sacaremilescentralus.blob.core.windows.net/vehicles/trees_org.png',
    },
    {
      value: 'https://sacaremilescentralus.blob.core.windows.net/vehicles/caremiles_icon.png',
      label: 'Caremiles',
      imageSrc: 'https://sacaremilescentralus.blob.core.windows.net/vehicles/caremiles_icon.png',
    },
  ]
  const [treePlanted, setTreePlanted] = useState('show by partner')
  const [counter, setCounter] = useState('')
  const [plantedCoutArr, setPlantedCoutArr] = useState([
    {
      text: 'Show by Project',
      value: 'show by project', // show by project
      project_id: null, // project_id
      count: null,
    },
    {
      text: 'Show by Partner', // show by project
      value: 'show by partner', // show by project
      partner_id: null, // project_id
      count: null,
    },
  ])
  //   const getTodoList = useCallback(() => {
  //     setLoading(true)
  //     fetchTodoList(layout.state.workingUser.id)
  //       .then((items) => {
  //         setTodoListItemsCount(items?.toDoListItems.length)
  //         const grouped = toArray(groupBy(items?.toDoListItems, (todoItem) => todoItem.taskType))
  //         setTodoListItems(grouped)
  //       })
  //       .catch((err) => {
  //         defaultRequestErrorHandler(err)
  //       })
  //       .finally(() => {
  //         setLoading(false)
  //       })
  //   }, [layout.state.workingUser.id, setLoading])
  const InputChnage = (e) => {
    console.log(e.target.value)
  }
  //   const getQuotesData = () => {
  //     setLoading(true)
  //     QuoteListService.getQuotes(
  //       'TodoListsContext',
  //       {
  //         orgId: layout.state.org.organizationId,
  //         groupId: layout.state.group.id,
  //         customerId: layout.state.customer.userId,
  //       },
  //       { showDeleted: false, showExpired: false },
  //       (result) => {
  //         setQuotes(result)
  //         setLoading(false)
  //       },
  //       (err) => {
  //         if (err instanceof ConnectionError) {
  //           DisplayCallStatusMessages.displayMessages(err.callStatus)
  //         } else {
  //           console.error(err)
  //           if (err.name !== 'AbortError') {
  //             setLoading(false)
  //           }
  //         }
  //       },
  //     )
  //   }
  //   useEffect(() => {
  //     getTodoList()
  //   }, [])
  //   useEffect(() => {
  //     if (!layout.state.org.organizationId) {
  //       getQuotesData()
  //     } else if (!quotes.length) {
  //       getQuotesData()
  //     }
  //   }, [layout.state.org.organizationId, openFlyout])
  const providerValue = useMemo(
    () => ({
      currencyType,
      evcashCurrency,
      showToggle,
      languageWidgets,
      widgets,
      languageOptions,
      currencyOptions,
      localSwitch,
      trees,
      plantNo,
      brandingFile,
      gamificationOptions1,
      commercialsOptions,
      evcashProjects,
      evcahsMinContribute,
      evcahsMinContributePrice,
      evcashPreview,
      step,
      brandingTitle,
      brandingMessage,
      gamificationOptions,
      locLangevcashCurrency,
      locLangplantTitle,
      locLangbrandingMessage,
      locLangplantNo,
      locLangtrees,
      evcashPoweredByOptions,
      locLangevcashCurrencyObj,
      evcashCurrencyObj,
      widgetId,
      evcashThankyouPreview,
      projects,
      projectId,
      thankyouTitle,
      thankyouMessage,
      thankyouPowerdBy,
      gamificationOptionsText,
      thankyouPowerdByText,
      customerOffer,
      localthankyouTitle,
      localthankyouMessage,
      userInformation,
      statickeyorder,
      setstatickeyorder,
      statickeydelivery,
      counter,
      setCounter,
      BrandInformation,
      setBrandInformation,
      setstatickeydelivery,
      WithOurCompliments,
      plantedCoutArr,
      treePlanted,
      setTreePlanted,
      setPlantedCoutArr,
      setWithOurCompliments,
      setGamificationOptionsText,
      setuserInformation,
      setlocalthankyouTitle,
      setlocalthankyouMessage,
      setThankyouPowerdByText,
      setcustomerOffer,
      setWidgetId,
      setshowToggle,
      setprojectId,
      setthankyouTitle,
      setthankyouMessage,
      setthankyouPowerdBy,
      setprojects,
      setevcashThankyouPreview,
      setEvcashCurrencyObj,
      setLocLangevcashCurrencyObj,
      setCurrencyType,
      setEvcashCurrency,
      setLanguageWidgets,
      setWidgets,
      setLanguageOptions,
      setCurrencyOptions,
      setLocalSwitch,
      setTrees,
      setPlantNo,
      setbrandingFile,
      setGamificationOptions1,
      setCommercialsOptions,
      setevcashProjects,
      setevcahsMinContribute,
      setevcahsMinContributePrice,
      setevcashPreview,
      setStep,
      setbrandingTitle,
      setbrandingMessage,
      setGamificationOptions,
      setLocLangevcashCurrency,
      setLocLangplantTitle,
      setLocLangbrandingMessage,
      setLocLangplantNo,
      setLocLangtrees,
      // add function
      InputChnage,
    }),
    [
      currencyType,
      showToggle,
      evcashCurrency,
      languageWidgets,
      widgets,
      languageOptions,
      currencyOptions,
      localSwitch,
      trees,
      plantNo,
      brandingFile,
      gamificationOptions1,
      commercialsOptions,
      evcashProjects,
      evcahsMinContribute,
      evcahsMinContributePrice,
      evcashPreview,
      step,
      brandingTitle,
      brandingMessage,
      gamificationOptions,
      locLangevcashCurrency,
      locLangplantTitle,
      locLangbrandingMessage,
      locLangplantNo,
      locLangtrees,
      evcashPoweredByOptions,
      locLangevcashCurrencyObj,
      evcashCurrencyObj,
      widgetId,
      evcashThankyouPreview,
      projects,
      projectId,
      thankyouTitle,
      thankyouMessage,
      thankyouPowerdBy,
      gamificationOptionsText,
      thankyouPowerdByText,
      customerOffer,
      localthankyouTitle,
      localthankyouMessage,
      userInformation,
      statickeyorder,
      statickeydelivery,
      WithOurCompliments,
      BrandInformation,
      setBrandInformation,
      setWithOurCompliments,
      plantedCoutArr,
      treePlanted,
      setTreePlanted,
      counter,
      setCounter,
      setPlantedCoutArr,
      setstatickeydelivery,
      setstatickeyorder,
      setGamificationOptionsText,
      setuserInformation,
      setlocalthankyouTitle,
      setlocalthankyouMessage,
      setcustomerOffer,
      setThankyouPowerdByText,
      setWidgetId,
      setshowToggle,
      setthankyouTitle,
      setthankyouMessage,
      setthankyouPowerdBy,
      setprojectId,
      setprojects,
      setevcashThankyouPreview,
      setEvcashCurrencyObj,
      setLocLangevcashCurrencyObj,
      setCurrencyType,
      setEvcashCurrency,
      setLanguageWidgets,
      setWidgets,
      setLanguageOptions,
      setCurrencyOptions,
      setLocalSwitch,
      setTrees,
      setPlantNo,
      setbrandingFile,
      setGamificationOptions1,
      setCommercialsOptions,
      setevcashProjects,
      setevcahsMinContribute,
      setevcahsMinContributePrice,
      setevcashPreview,
      setStep,
      setbrandingTitle,
      setbrandingMessage,
      setGamificationOptions,
      setLocLangevcashCurrency,
      setLocLangplantTitle,
      setLocLangbrandingMessage,
      setLocLangplantNo,
      setLocLangtrees,
      //addfunctions
      InputChnage,
    ],
  )
  return <WidgetsContext.Provider value={providerValue}>{children}</WidgetsContext.Provider>
}
WidgetsContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
