import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  const year = new Date().getFullYear()
  return (
    <CFooter>
      <div>
        <span id="spanYear"></span> &copy; Caremiles Inc {year}. All rights reserved.
      </div>
      <div>
        <ul className="footer-links">
          <li>
            <a href="/TermsConditions">Terms and Conditions</a>
          </li>
          <li>
            <a href="/faq">FAQ</a>
          </li>
          <li>
            <a href="/contactUs">Contact Us</a>
          </li>
        </ul>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
