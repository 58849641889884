import { useState, useEffect } from 'react'
import { getToken, generateErrorWithStatusText, logout } from '../helpers/GeneralFunctions'
import { useNavigate } from 'react-router-dom'

const useFetch = (url = '', method = 'GET', body = {}, headers = {}) => {
  const [data, setData] = useState(null)
  const [isPending, setIsPending] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    let accessToken = getToken()
    callApi(accessToken)
  }, [url])

  function callApi(accessToken) {
    headers['access-token'] = accessToken
    const requestOptions = {
      method,
      headers,
    }

    const additionalOptions =
      method !== 'GET'
        ? {
            method: method, // *GET, POST, PUT, DELETE, etc.
            //mode: 'no-cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
              ...headers,
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(body), // body data type must match "Content-Type" header
          }
        : requestOptions

    fetch(url, additionalOptions)
      .then((res) => {
        console.log('useFetch(res)', res)
        if (!res.ok) {
          // error coming back from server
          if (res.code === 401) {
            navigate('/login')
          }
          throw generateErrorWithStatusText(res)

          //throw err
        }
        return res.json()
      })
      .then((data) => {
        console.log('useFetch(data)', data)
        setIsPending(false)
        setData(data)
        setError(null)
      })
      .catch((err) => {
        console.log('useFetch(err)', err)
        if (err.statusText === 'Unauthorized') {
          logout()
        }

        // auto catches network / connection error
        setIsPending(false)
        setError(err.message)
      })
  }

  return { data, isPending, error }
}

export default useFetch
