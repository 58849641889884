import React from 'react'
import { CImage } from '@coreui/react'
import caremilesLogo from 'src/assets/images/hub-logo.png'

const AppHeaderLogout = () => {
  return (
    <div className="container-fluid">
      <div className="logo-div mt-3">
        <CImage src={caremilesLogo} alt="Logo" />
      </div>
    </div>
  )
}

export default AppHeaderLogout
